<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    computedInboxClass() {
      const { phone_number: phoneNumber, channel_type: type } = this.inbox;
      const classByType = getInboxClassByType(type, phoneNumber);
      return classByType;
    },
    computedInboxStyle() {
      const {id: id} = this.inbox;
      if(id==5 || id==9 || id==11 || id==13){return "redinbox"}
    },
  },
};
</script>
<style> .redinbox{color:red;}</style>
<template>
  <div
    class="inbox--name inline-flex items-center py-0.5 px-0 leading-3 whitespace-nowrap bg-none text-slate-600 dark:text-slate-500 text-xs my-0 mx-2.5"
  >
    <fluent-icon
      class="mr-0.5 rtl:ml-0.5 rtl:mr-0"
      :icon="computedInboxClass"
      size="12"
    />
    <strong :class="computedInboxStyle">{{ inbox.name }}</strong>
  </div>
</template>
